<template>
  <div id="camfrog-main-container">
    <component :is="layout">
      <router-view :layout.sync="layout" />
    </component>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    layout() {
      return this.$route.meta.layout || "base-layout";
    }
  }
}
</script>

<style lang="scss">
.link-common {
  color: #3D95CC;
}
</style>
