import Vue from 'vue'
import Vuex from 'vuex'
import room_subscriptions from './modules/room_subscriptions'
import checkout from './modules/checkout'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        room_subscriptions,
        checkout
    }
})
