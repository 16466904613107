const state = {
    roomTiersInfo: {},
};

const mutations = {
    SET_ROOM_TIERS_INFO(state, data) {
        state.roomTiersInfo = data;
    },
};

const actions = {
    setRoomTiersInfo({ commit }, roomTiersInfo) {
        commit('SET_ROOM_TIERS_INFO', roomTiersInfo);
    }
};

const getters = {
    getRoomTiersInfo: state => state.roomTiersInfo || {},

    getFeaturesByType: state => (type) => {
        return state.roomTiersInfo.features
            .filter(feature => feature[type] !== false)
            .map(feature => {
                if (typeof feature[type] === 'boolean' || typeof feature[type] === 'string') {
                    return {
                        name: feature.feature_name
                    }
                } else if (typeof feature[type] === 'number') {
                    return {
                        name: feature.feature_name,
                        value: feature[type]
                    }
                }
            });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
