<template>
  <div id="base-layout" class="base-layout">
    <div ref="container" class="base-container">
      <section class="header">
        <img src="@/assets/images/main/logo-grey.svg" alt="logo">
      </section>
      <section class="content">
        <slot />
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseLayout"
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap&subset=cyrillic,cyrillic-ext,latin-ext,vietnamese');
@import "../../src/style/reset.css";
.base-layout {
  background: url('~@/assets/images/main/checkout-bg.svg') no-repeat;
  background-position-y: 100px;
  background-size: cover;
}

.base-container {
  //height: calc(100vh - 67px);
  max-width: 960px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 84px;
  .header {
    position: absolute;
    left: 50%;
    top: 48px;
    transform: translate(-50%, -50%);
  }
  .content {
    //padding-bottom: 100px;
  }

  @media screen and (max-width: 1345px) {
    //width: 799px;
  }
  @media screen and (max-width: 1130px) {
    //width: 637px;
  }
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
}
*::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
}
*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 20px;
  border: 3px solid #fff;
  -webkit-box-shadow: none;
}
*::-webkit-scrollbar-thumb:window-inactive {
  background: none;
}
body {
  font-family: "Open Sans", sans-serif;
  overflow: overlay; /*for webkit only*/
}
button {
  cursor: pointer;
}
h1 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}
h1.category-name {
  color: #333333;
}
.link-common {
  color: #2E6DCB;

  &:hover {
    color: #0E57C4;
  }
  &:active {
    color: #0047B0;
  }
}

</style>