import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from './i18n';

Vue.use(VueRouter);

const LandingMobile = () => import("./views/LandingMobile");
const LandingSSO = () => import("./views/LandingSSO");
const RoomSharingPage = () => import("./views/RoomSharingPage");
const Checkout = () => import("./views/Checkout");

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/:lang(en|ru|de|ar|id|it|sr|th|tr|fr|zh-s|zh-t|ja|bs|cs|ko)?/main',
      component: { render: h => h('router-view') },
      children: [
        { path: 'info-mobile', component: LandingMobile },
        { path: 'join-room', component: RoomSharingPage },
        { path: 'auth', component: LandingSSO },
        {
          path: 'upgrade',
          component: Checkout,
          children: [
            {
              path: 'room',
              component: () => import("./views/Checkout/Room"),
              meta: { layout: "base-layout" }
            }
          ]
        },
      ]
    },

  ],
});

router.beforeEach((to, from, next) => {
  const { lang } = to.params;
  i18n.locale = lang || 'en';
  next();
});

export default router;
