const state = {
    checkoutSettings: {},
    pricingPlans: [],
    checkoutPageId: null,
};

const mutations = {
    SET_CHECKOUT_SETTINGS(state, settings) {
        state.checkoutSettings = settings;
    },
    SET_PRICING_PLANS(state, plans) {
        state.pricingPlans = plans;
    },
    SET_CHECKOUT_CURRENCY(state, currency) {
        if (state.checkoutSettings.currency) {
            state.checkoutSettings.currency.value = currency;
        }
    },
    SET_CHECKOUT_PAGE_ID(state, pageId) {
        state.checkoutPageId = pageId;
    },
};

const actions = {
    setCheckoutSettings({ commit }, settings) {
        commit('SET_CHECKOUT_SETTINGS', settings);
    },
    setPricingPlans({ commit }, plans) {
        commit('SET_PRICING_PLANS', plans);
    },

    updateCheckoutCurrency({ commit }, currency) {
        commit('SET_CHECKOUT_CURRENCY', currency);
    },
    setCheckoutPageId({ commit }, pageId) {
        commit('SET_CHECKOUT_PAGE_ID', pageId);
    },
};

const getters = {
    getCheckoutSettings: state => state.checkoutSettings || {},
    selectedPricingPlan: state => {
        const { currency, selected_product_id } = state.checkoutSettings || {};
        const selectedCurrency = currency?.value || 'USD';
        const selectedProductId = selected_product_id || 0;

        return state.pricingPlans.find(plan =>
            plan.currency === selectedCurrency && plan.product_id === selectedProductId
        ) || {};
    },
    getCheckoutPageId: state => state.checkoutPageId,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
