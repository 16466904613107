import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import i18n from './i18n';
import router from './router';
import store from './store/store';
import App from './App';
import BaseLayout from "../layouts/BaseLayout";

Vue.component("base-layout", BaseLayout)

axios.defaults.withCredentials = true;
Vue.use(VueAxios, axios);

// Prime Vue
import PrimeVue from 'primevue/config';
import "@/style/prime_theme.scss";
import 'primevue/resources/primevue.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import Button from 'primevue/button';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import SelectButton from 'primevue/selectbutton';

Vue.component('Button', Button);
Vue.component('TabView', TabView);
Vue.component('TabPanel', TabPanel);
Vue.component('Dropdown', Dropdown);
Vue.component('InputText', InputText);
Vue.component('SelectButton', SelectButton);

Vue.use(PrimeVue);

import { Swiper, Pagination } from 'swiper/js/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
import 'swiper/css/swiper.min.css';

Swiper.use([Pagination]);
Vue.use(getAwesomeSwiper(Swiper));

Vue.config.productionTip = false;

new Vue({
  el: '#camfrog-main-pages',
  i18n,
  router,
  store,
  components: {
    App
  },
  methods: {},
  render: h => h(App)
});
