import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  "en": {
    "videochat_and_meet" : "Video Chat and Meet New People",
    "download_camfrog" : "Download Camfrog",
    "100m_downloads" : "100 million Downloads",
    "join_camfrog_description" : "Join Camfrog, the #1 live video chat community to meet others just like you!",
    "start_meeting_people" : "Start meeting new people today.",
    "video_chat" : "Video chat",
    "meet_people_globally" : "Meet people globally",
    "get_rewards" : "Get rewards",
    "have_fun" : "Have fun!",
    "and_much_more" : "And much more!",
    "feel_part_of_community": "Feel like you're part of a real community!",
    "join_conversation" : "Join the Conversation",
    "start_free_videochat" : "Start a free video chat, voice or text group chat with anyone on any device.",
    "testimonials": {
      "excellent_program" : "👍 An excellent program for learning languages and chatting with new friends",
      "helpful" : "Camfrog helped me a lot when I was down in my life. I owe this community a big thank you for being there for me.",
      "great_app" : "Great app for meeting new people",
      "love_this" : "I love this app. I met some of my closest friends on Camfrog.",
    },
    "join_room_btn": "Join chat room now",
    "sign_up_and_install": "Sign up & Install Camfrog",
    "available": "Available on:",
    "checkout": {
      "choose_room_subscription": "Choose a Room Subscription",
      "selected_plan": "Selected Plan",
      "paypal": "PayPal",
      "other_payment": "Other Payment",
      "credit_card": "Credit Card",
      "pay_now": "Pay Now",
      "upgrade": "Upgrade",
      "cancel_subscription": "This is a subscription product. You may cancel your subscription at any time.",
      "terms": "By clicking «Pay Now», you agree your subscription will auto-renew until you cancel so we can provide you with uninterrupted access to premium benefits. See our",
      "terms_link": "Terms of Service",
      "email_label": "Email",
      "invalid_email_address": "Invalid email address.",
      "card_label": "Card Number",
      "unlimited_users": "Unlimited users",
      "roomcast_credits": "Roomcast Credit | Roomcast Credits",
      "video_slots": "Video Slot | Video Slots",
      "bonus_coins": "Bonus coin | Bonus coins",
      "priority_listing": "Priority Listing",
      "room_owner_flair": "Room Owner Flair",
      "coloured_room_name": "Coloured room name",
      "short_room_name": "Premium short room name",
      "your_current_tier": "Your current tier",
      "monthly": "/mo",
      "annual": "/yr",
      "billed_annually": "Billed annually",
      "subscription_renewal": "Your subscription will be renewed at",
      "coins": "coins",
    },

  },
  "id": {
    "videochat_and_meet": "Obrolan Video dan Bertemu Orang Baru",
    "download_camfrog": "Unduh Camfrog",
    "100m_downloads": "100 juta Unduh",
    "join_camfrog_description": "Bergabunglah dengan Camfrog, komunitas obrolan video langsung #1 untuk bertemu orang lain seperti Anda!",
    "start_meeting_people": "Mulai bertemu orang baru hari ini",
    "video_chat": "Obrolan video",
    "meet_people_globally": "Temui orang-orang di seluruh dunia",
    "get_rewards": "Dapatkan hadiah",
    "have_fun": "Selamat bersenang-senang!",
    "and_much_more": "Dan masih banyak lagi!",
    "feel_part_of_community": "Merasa seperti Anda adalah bagian dari komunitas nyata!",
    "join_conversation": "Gabung Percakapan",
    "start_free_videochat": "Mulai obrolan video, suara, atau grup teks gratis dengan siapa pun di perangkat apa pun.",
    "testimonials": {
      "excellent_program": "👍 Program luar biasa untuk belajar bahasa dan mengobrol dengan teman baru",
      "helpful": "Camfrog banyak membantu saya ketika saya sedang turun dalam hidup saya. Saya berutang  kepada komunitas ini terima kasih telah berada di sana untuk saya.",
      "great_app": "Aplikasi hebat untuk bertemu orang baru",
      "love_this": "Saya suka aplikasi ini. Saya bertemu dengan beberapa teman terdekat saya di Camfrog."
    }
  },
  "th": {
    "videochat_and_meet": "วีโอแชทและรู้จักเพื่อนใหม่",
    "download_camfrog": "ดาวน์โหลด Camfrog",
    "100m_downloads": "ดาวน์โหลด 100 ล้านครั้ง",
    "join_camfrog_description": "เข้าร่วม Camfrog วิดีโอแชทสดอันดับ 1 เพื่อพบปะกับผู้คนอื่นๆ เช่นเดียวกับคุณ!",
    "start_meeting_people": "เริ่มรู้จักผู้คนใหม่ๆ ได้วันนี้",
    "video_chat": "วีโอแชท",
    "meet_people_globally": "พบปะผู้คนทั่วโลก",
    "get_rewards": "รับรางวัล",
    "have_fun": "ขอให้สนุก!",
    "and_much_more": "และอื่นๆ อีกมากมาย!",
    "feel_part_of_community": "เหมือนคุณเป็นส่วนหนึ่งของชุมชน!",
    "join_conversation": "เริ่มการสนทนา",
    "start_free_videochat": "เริ่มวิดีโอแชท แชทด้วยเสียงหรือข้อความแบบกลุ่มฟรีกับทุกคนบนอุปกรณ์ใดก็ได้",
    "testimonials": {
      "excellent_program": "👍 สุดยอดโปรแกรมสำหรับการเรียนรู้ภาษาและพูดคุยกับเพื่อนใหม่",
      "helpful": "Camfrog ช่วยฉันได้มากเมื่อตอนที่ฉันรู้สึกแย่ในชีวิต ฉันเป็นหนี้ชุมชนนี้ ขอบคุณมากที่อยู่ข้างๆ ฉัน",
      "great_app": "เป็นแอพที่ดีมากสำหรับพบปะผู้คนใหม่ ๆ",
      "love_this": "ฉันชอบแอพนี้ เพราะฉันได้เจอเพื่อนสนิทฉันใน Camfrog"
    }
  },
  "tr": {
    "videochat_and_meet": "Görüntülü Sohbet ve Yeni İnsanlarla Tanış",
    "download_camfrog": "Camfrog'u indir",
    "100m_downloads": "100 milyon indirildi",
    "join_camfrog_description": "Senin gibi başkalarıyla tanışmak için #1 numaralı canlı görüntülü sohbet topluluğu Camfrog'a katıl!",
    "start_meeting_people": "Bugün yeni insanlarla tanışmaya başla.",
    "video_chat": "Görüntülü sohbet",
    "meet_people_globally": "Dünya çapında insanlarla tanış",
    "get_rewards": "Ödüller al",
    "have_fun": "Eğlen!",
    "and_much_more": "Ve daha fazlası!",
    "feel_part_of_community": "Gerçek bir topluluğun parçası gibi hisset!",
    "join_conversation": "Görüşmeye katıl",
    "start_free_videochat": "Herhangi bir cihazdaki herkesle ücretsiz görüntülü sohbet, sesli veya yazılı grup sohbeti başlat.",
    "testimonials": {
      "excellent_program": "👍 Dil öğrenmek ve yeni arkadaşlarla sohbet etmek için mükemmel bir program",
      "helpful": "Camfrog hayatımda kötü durumdayken bana çok yardımcı oldu. Bu topluluğa benim için orada olduğunuz için büyük bir teşekkür borçluyum.",
      "great_app": "Yeni insanlarla tanışmak için harika bir uygulama",
      "love_this": "Bu uygulamayı seviyorum. En yakın arkadaşlarımla Camfrog'da tanıştım."
    }
  }
}

export default new VueI18n({
  locale: 'en',
  messages
});
